/*
 * @Author: 祁明华 7681539@qq.com
 * @Date: 2024-03-27 10:48:03
 * @LastEditors: 祁明华 7681539@qq.com
 * @LastEditTime: 2024-04-17 13:57:04
 * @FilePath: \天百基地（PC端）\src\api\auth\index.js
 * @Description: 权限API
 */
import qs from "qs";
import axios from "../http";

export function login(data) {
  return axios.post("/user/login", qs.stringify(data));
}

export function loginOut(data) {
  return axios.post("/user/loginOut", qs.stringify(data));
}
