import axios from "../http";
import qs from "qs";

export async function getTableData(url, data, config) {
  return await axios({
    method: "post",
    url,
    data: qs.stringify(data),
    ...config,
  });
}

export async function uploadImage(data) {
  return await axios.post("/common/uploadImage", data);
}

export async function showImage(params) {
  return await axios.get("/common/showPic", { params });
}

export async function uploadVideo(data) {
  return await axios.post("/common/uploadVideo", data);
}
