/*
 * @Author: 祁明华 7681539@qq.com
 * @Date: 2024-03-27 10:48:03
 * @LastEditors: 祁明华 7681539@qq.com
 * @LastEditTime: 2024-04-18 09:15:05
 * @FilePath: \天百基地（PC端）\src\api\http.js
 * @Description: 配置api
 */
import router from "@/router";
import axios from "axios";
import { Notification } from "element-ui";

const baseURL =
  process.env.NODE_ENV === "development" ? "/api" : "https://tbjd.batar.cn/api";

const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    contentType: "application/x-www-form-urlencoded",
  },
});

const errorMessageMap = new Map([
  [400, "无效请求"],
  [401, "未授权(401)"],
  [403, "拒绝访问（403）"],
  [404, "请求出错(404)"],
  [408, "请求超时（408）"],
  [500, "服务器错误（500）"],
  [501, "服务未实现（501）"],
  [502, "网络错误（502）"],
  [503, "服务不可用（503）"],
  [504, "网络超时（504）"],
  [505, "HTTP版本不受支持（505）"],
  [999999, "系统错误, 请联系管理员！"],
]);

function notifiFunc(statusCode, message, errorType = "error") {
  Notification({
    title: errorType === "error" ? "错误" : "警告",
    message: message || errorMessageMap.get(statusCode),
    type: errorType === "error" ? "error" : "warning",
    position: "top-left",
  });
}

// request拦截器
instance.interceptors.request.use(
  (config) => {
    config.headers.accessToken = `${localStorage.accessToken || ""}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    if (error && error.response) {
      const errorResponse = error.response;
      const code = errorResponse.status;
      if (error.response.data === "未登录") {
        localStorage.clear();
        router.replace({ path: "/login" });
      }
      notifiFunc(code, errorResponse.data || undefined);
    }
    return Promise.reject(error);
  }
);

export default instance;
